<template>
  <div class="home">
    <Header bg="none" class="component-header" />
    <div class="home-header">
      <el-carousel height="4.166667rem" :interval="3000" arrow="never">
        <el-carousel-item>
          <img src="../../static/img/home/banner1.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <img src="../../static/img/home/banner2.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <img src="../../static/img/home/banner3.png" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="business-area company-advantage-wrap">
      <div class="area-title">
        <img class="area-title-img" src="../../static/img/home/business.png"
          alt="" />
        <div class="area-title-text">业务领域</div>
      </div>
      <div class="area-content">
        <div class="area-content-item" @mouseenter="mouseover(i)"
          @mouseleave="mouseLeave(i)"
          :class="i === itemIndex ? 'current curr-zz' : ''"
          v-for="(item, i) in areaImgs" :key="i">
          <div class="mask">
            <div class="mask_zz"></div>
            <img class="mask_bg"
              :src="require('../../static/img/home/' + item.url + '.png')"
              alt="" />
            <p class="business_introduce">
              <span class="title">{{ item.text }}</span>
              <span class="m-t3">{{ item.introduce }}</span>
            </p>
          </div>
          <div class="mask_floor">
            <img class="mask_icon" :class="item.icon"
              :src="require('../../static/img/home/' + item.icon + '.png')"
              alt="" />
            <p class="mask_title">{{ item.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="company-advantage-wrap company-advantage-wrap-bg">
      <div class="company-advantage">
        <div class="area-title">
          <img class="area-title-img" src="../../static/img/home/company.png"
            alt="" />
          <div class="area-title-text">公司简介</div>
        </div>
        <div class="company-info-content wow bounceInLeft">
          <div class="company-info-content-box">
            <div class="left-img">
              <img src="../../static/img/home/cross7.png" alt="" />
            </div>
            <div class="right-text">
              <h3>河南睿腾信息技术有限公司</h3>
              <p>
                河南睿腾信息技术有限公司成立于2009年，专注于人工智能视觉领域的信息化系统建设与服务。公司以AI+场景为基础致力于人工智能在智慧城市、智慧校园、智慧园区、智慧政务等领域的产品化服务应用。公司拥有多名资深的人工智能领域高级工程师、研发团队、项目经理和运维工程师，通过了ISO9001质量认证体系，取得多项软件荣誉资质。同时公司是商汤科技河南区域总代理及核心战略合作伙伴，致力成为AI智慧化信息建设领域的领跑者。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="company-advantage-wrap company-advantage-wrap-bg-2">
      <div class="company-advantage">
        <div class="area-title">
          <div class="area-title-outter">睿腾四大优势</div>
        </div>
        <div class="advantage-container">
          <dl class="wow bounceInLeft" data-wow-delay="1.2s">
            <dt>
              <h3>优质产品</h3>
              <div class="line"></div>
            </dt>
            <dd>协助客户建立最具竞争力的信息化系统</dd>
          </dl>
          <dl class="wow bounceInLeft" data-wow-delay="0.9s">
            <dt>
              <h3>创新产品</h3>
              <div class="line"></div>
            </dt>
            <dd>公司集设计、施工、开发、销售为一体</dd>
          </dl>
          <dl class="wow bounceInLeft" data-wow-delay="0.6s">
            <dt>
              <h3>资质雄厚</h3>
              <div class="line"></div>
            </dt>
            <dd>公司拥有完善的经营体制通过权威机构的质量认证</dd>
          </dl>
          <dl class="wow bounceInLeft" data-wow-delay="0.3s">
            <dt>
              <h3>售后服务</h3>
              <div class="line"></div>
            </dt>
            <dd style="width:240px;">
              1年内免费维修、1-3年内收取维修材料费用、3年及以上有偿维修。接到客户电话后1小时内回应、简易问题24小时内解决、非常规问题72小时内现场解决。
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <!-- <div class="company-advantage-wrap company-advantage-wrap-bg-3">
      <div class="company-advantage">
        <div class="area-title">
          <img class="area-title-img" src="../../static/img/home/news.png" alt="">
          <div class="area-title-text">公司动态</div>
        </div>
        <div class="company-info-content">
          <div class="company-info-content-box">
            <div class="left">
              <img src="../../static/img/home/cross8.png" alt="">
              <h3>下一个风口：互联网+</h3>
              <p>“互联网+”一下子成为了社会业界追捧的热词，这是我两年前始料未及的。腾讯当时已在这个方向上积极探索了。...</p>
            </div>
            <div class="right">
              <h3>河南睿腾信息技术有限公司</h3>
              <p> 河南睿腾信息技术有限公司成立于2009年，专注于人工智能视觉领域的信息化系统建设与服务。公司以AI+场景为基础致力于人工智能在智慧城市、智慧校园、智慧园区、智慧政务等领域的产品化服务应用。公司拥有多名资深的人工智能领域高级工程师、研发团队、项目经理和运维工程师，通过了ISO9001质量认证体系，取得多项软件荣誉资质。同时公司是商汤科技河南区域总代理及核心战略合作伙伴，致力成为AI智慧化信息建设领域的领跑者。</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="company-cate company-advantage-wrap">
      <div class="area-title">
        <img class="area-title-img" src="../../static/img/home/cooperation.png"
          alt="" />
        <div class="area-title-text">合作伙伴</div>
      </div>
      <div class="cate-content">
        <div class="swiper-container2 swiper-no-swiping">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-01.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-02.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-03.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-04.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-05.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-06.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-07.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-container3 swiper-no-swiping">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-08.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-09.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-10.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-11.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-12.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-13.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-14.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-container4 swiper-no-swiping">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-07.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-06.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-15.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-16.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-17.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-03.png" alt="" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="detail">
                <i class="line"></i>
                <img src="../../static/img/home/home-logo-01.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../components/Header";
import Swiper from "swiper";

export default {
  name: "Home",
  components: {
    Header,
  },
  data () {
    return {
      itemIndex: null,
      mySwiper: null,
      mySwiper2: null,
      mySwiper3: null,
      mySwiper4: null,
      areaImgs: [
        {
          url: "areaimg1",
          icon: "gov",
          text: "政府行业信息化",
          introduce: "智能会议、智能安防、智能门禁、一站式解决方案",
        },
        {
          url: "areaimg2",
          icon: "path1",
          text: "人脸识别",
          introduce: "异常报警、人脸校验、门禁管理、多元化使用场景",
        },
        {
          url: "areaimg3",
          icon: "path4",
          text: "智慧校园",
          introduce: "校务管理、应用系用平台、IT硬件基础设施、集中解决方案",
        },
        {
          url: "areaimg4",
          icon: "room",
          text: "智慧宿舍",
          introduce: "基础信息管理、智能查寝、白名单管理、智能通道",
        },
        {
          url: "areaimg5",
          icon: "comm",
          text: "智慧社区",
          introduce: "社区信息、物业管理、智能访客、智慧办公",
        },
        {
          url: "areaimg6",
          icon: "path3",
          text: "智能安防",
          introduce: "陌生人管理、智能报警、校园智能安防系统、智慧社区安防系统",
        },
      ],
    };
  },
  methods: {
    mouseover (i) {
      this.itemIndex = i;
    },
    mouseLeave (i) {
      this.itemIndex = null;
      console.log(i);
      // this.itemIndex = 0
    },
    onResize () {
      // this.mySwiper.update()
      this.mySwiper2.update();
    }
  },
  mounted () {
    new this.$wow.WOW().init();

    // window.addEventListener('resize', this.onResize);
    this.mySwiper = new Swiper(".swiper-container", {
      loop: true, // 循环模式选项
      slidesPerView: 2.5,
      spaceBetween: 10,
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      //  on: {
      //     resize: function () {
      //       setTimeout(() => {
      //             this.mySwiper.update()
      //       }, 500)   //延时器很重要，不加可能会出错
      //     }
      //   },
      // 如果需要前进后退按钮
      navigation: {
        prevEl: ".swiper-button-next",
        nextEl: ".swiper-button-prev",
      },
    });
    this.mySwiper2 = new Swiper(".swiper-container2", {
      loop: true, // 循环模式选项
      speed: 4000,
      freeMode: true,
      autoplay: {
        //匀速滚动
        delay: 0, //间隔时间0秒
        stopOnLastSlide: false,
        disableOnInteraction: false, //触摸后仍然执行
      },
      slidesPerView: 6,
      spaceBetween: 30,
    });
    this.mySwiper3 = new Swiper(".swiper-container3", {
      loop: true, // 循环模式选项
      speed: 4000, //滚动速度2500
      freeMode: true,
      autoplay: {
        //匀速滚动
        delay: 0, //间隔时间0秒
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      slidesPerView: 6,
      spaceBetween: 30,
    });
    this.mySwiper4 = new Swiper(".swiper-container4", {
      // width: 1312,
      loop: true, // 循环模式选项
      speed: 4000,
      freeMode: true,
      autoplay: {
        //匀速滚动
        delay: 0, //间隔时间0秒
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      slidesPerView: 6,
      spaceBetween: 30,
    });
  },
};
</script>
<style scoped lang="scss">
.home {
  min-width: 1200px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  .component-header {
    position: absolute;
    z-index: 99;
    display: flex;
    align-items: center;
  }
  .home-header {
    box-sizing: border-box;
    width: 100%;
    overflow-x: hidden;
    img {
      width: 100%;
    }
  }
  .business-area {
    margin-top: 0.3125rem;
    .area-content {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      .area-content-item {
        width: 1.427083rem;
        position: relative;
        background: #000;
        transition: all 0.3s;
        color: #fff;
        font-size: 0.104167rem;
        overflow: hidden;
        flex: 1;
        .mask {
          position: relative;
          width: 550px;
          height: 700px;
          .business_introduce {
            position: absolute;
            width: 100%;
            z-index: 100;
            visibility: hidden;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0 0.260417rem;
            .title {
              height: 0.171875rem;
              font-size: 0.177083rem;
              font-family: PingFang SC;
              font-weight: bold;
              line-height: 0.15625rem;
              color: #ffffff;
              padding-bottom: 0.177083rem;
            }
            .m-t3 {
              font-size: 0.104167rem;
              margin-top: 0.177083rem;
              line-height: 0.1875rem;
            }
          }
          .mask_zz {
            position: absolute;
            width: 100%;
            height: 100%;
            background: #000;
            opacity: 0.6;
          }
        }
        .mask_floor {
          position: absolute;
          width: 0.833333rem;
          left: 50%;
          top: 50%;
          z-index: 10;
          transform: translate(-50%, -50%);
          display: flex;
          flex-direction: column;
          align-items: center;
          .gov {
            width: 50px;
            height: 41px;
          }
          .path1 {
            width: 37px;
            height: 41px;
          }
          .path4 {
            width: 47px;
            height: 41px;
          }
          .room {
            width: 48px;
            height: 42px;
          }
          .comm {
            width: 43px;
            height: 41px;
          }
          .path3 {
            width: 37px;
            height: 40px;
          }
          .mask_title {
            margin-top: 0.15625rem;
          }
        }
      }
      .current {
        width: 550px;
        flex: 2;
        transition: 0.3s ease;
      }
      .current .mask_floor {
        display: none !important;
      }
      .current .business_introduce {
        visibility: visible !important;
      }
      .current .mask_zz {
        opacity: 0.3 !important;
      }
    }
  }
  .company-cate {
    margin-top: 0.3125rem;
    .area-title-img {
      width: 3.15625rem !important;
    }
    .cate-content {
      padding-bottom: 0.3125rem;
      .swiper-container3 {
        margin: 0.15625rem 0;
        padding-left: 0.416667rem;
      }
      .swiper-container4 {
        padding-left: 0.15625rem;
      }
      .swiper-container2,
      .swiper-container3,
      .swiper-container4 {
        width: 100%;
        overflow: hidden;
        .swiper-wrapper {
          width: 100%;
        }
        .swiper-slide {
          .detail {
            height: 0.520833rem;
            background: #fff;
            border: 1px solid #f1f1f1;
            box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 2px;
            padding: 0 20px;
            position: relative;
            .line {
              width: 0;
              height: 3px;
              background-color: #4285f4;
              position: absolute;
              top: 0;
              left: 0;
              transition: width 0.5s linear;
            }
            &:hover {
              .line {
                width: 100%;
              }
              border-radius: 5px 5px 0px 0px;
              box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
            }
            img {
            }
          }
        }
      }
    }
  }
}
::v-deep .el-carousel__button {
  width: 0.1875rem;
  height: 0.020833rem;
}
::v-deep .el-carousel__indicator.is-active button {
  background-color: #4285f4;
}
.swiper-button-prev {
  background-image: url("../../static/img/case-show/arw2.png");
  background-size: 100% 100%;
  left: 2.770833rem;
  z-index: 100;
  // top: 1.661458rem;
  top: 1.692708rem; //321
  // bottom: 92.5px;
}
.swiper-button-next {
  background-image: url("../../static/img/case-show/arw1.png");
  background-size: 100% 100%;
  right: 0.104167rem;
  top: 1.692708rem;
  z-index: 100;
}
.swiper-arw {
  position: absolute;
  outline: none;
  width: 40px;
  height: 40px;
  &::after {
    content: "";
  }
}
.swiper-wrapper {
  transition-timing-function: linear;
}

// 2020.12.19
.company-advantage-wrap {
  .area-title {
    background: none;
    font-size: 0.208333rem;
    text-align: center;
    margin-bottom: 0.416667rem;
    .area-title-img {
      width: 2.15625rem;
    }
    .area-title-text {
      margin-top: -0.4rem;
    }
  }
  .company-advantage {
    width: 6.25rem;
    padding: 0.416667rem 0 0;
    margin: 0 auto;
    .company-info-content-box {
      width: 6.25rem;
      margin: 0 auto;
      .left-img,
      .right-text {
        display: inline-block;
        vertical-align: middle;
      }
      .left-img {
        width: 3.239583rem;
        height: 2.552083rem;
        margin-right: 0.3125rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right-text {
        width: 2.697917rem;
        text-align: center;
        line-height: 0.208333rem;
        h3 {
          font-size: 0.135417rem;
          margin-bottom: 0.15625rem;
        }
        p {
          font-size: 0.104167rem;
          text-align: left;
          text-indent: 2em;
        }
      }
    }
  }
}
.company-advantage-wrap-bg {
  background: url(../../static/img/home/home-bg-01.png) no-repeat;
  background-size: cover;
  padding-bottom: 0.416667rem;
}
.company-advantage-wrap-bg-2 {
  height: 3.125rem;
  background: url(../../static/img/home/home-bg-02.png) no-repeat #272f4e;
  background-size: cover;
  .company-advantage {
    color: #fff;
    .advantage-container {
      dl {
        width: 1.005208rem;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.739583rem;
        &:last-child {
          margin-right: 0;
        }
        dt {
          h3 {
            font-size: 0.15625rem;
            font-weight: 400;
          }
          .line {
            width: 0.15625rem;
            height: 0.03125rem;
            background: #3d6eff;
            margin: 0.135417rem 0 0.135417rem 0.1875rem;
          }
        }
        dd {
          font-size: 0.083333rem;
          line-height: 0.145833rem;
        }
      }
    }
  }
}
.company-advantage-wrap-bg-3 {
  background: url(../../static/img/home/home-bg-03.png) no-repeat;
  background-size: cover;
  .company-advantage {
    .area-title {
      .area-title-img {
        width: 1.28125rem;
      }
    }
    .company-info-content {
      .company-info-content-box {
        .left,
        .right {
          display: inline-block;
          vertical-align: middle;
        }
        .left {
          h3 {
            font-size: 0.09375rem;
            color: #5580fe;
          }
        }
        .right {
          width: 3.28125rem;
        }
      }
    }
  }
}
</style>
